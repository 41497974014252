import React from 'react'

export default function Footer() {
    return (
        <footer className="bg-primary text-[#c7c7c7] text-sm font-thin py-4 md:py-8 px-2 flex justify-center items-center ">
            <div className="container mx-auto flex flex-col">
                <p className="uppercase">
                    Ram Environmental Consulting
                </p>
                <p>
                    Canmore, Alberta
                </p>
                <p>
                    <a href="tel:4037633568">403.763.3568</a>
                </p>
                <p>
                    <a href="mailto:info@ramenviro.ca">info@ramenviro.ca</a>

                </p>
            </div>
        </footer>
    )
}
