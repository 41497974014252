import React from 'react'
import {Helmet} from "react-helmet";
import {graphql, useStaticQuery} from "gatsby";


export default function Seo({ description, lang, meta, keywords, title }) {
    const { site, logo } = useStaticQuery(graphql`
        query {
          site {
            meta: siteMetadata {
              title
              description
            }
          }
          logo: file(relativePath: {eq: "logo.png"}) {
            publicURL
          }
        }
      `);

    const t = title ? `${title} | RAM Enviro` : site.meta.title;
    const d = description || site.meta.description;


    return (
        <Helmet>
            <title>{t}</title>
            <meta name="og:title" content={t}/>
            <meta name="description" content={d}/>
            <meta name="og:description" content={d}/>
            <meta name="og:image" content={logo.publicURL}/>
        </Helmet>
    )
}
