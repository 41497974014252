import * as React from 'react';
import Logo from '../images/logo.svg';
import PhoneIcon from '../images/phone.svg';
import EmailIcon from '../images/email.svg';
import {Link} from "gatsby";

export default function Nav() {
    return (
        <nav className="py-4 xl:py-8 px-2">
            <div className="container mx-auto flex justify-between items-center">
                <Link to="/">
                    <Logo className="w-28 sm:w-48 xl:w-64"/>
                </Link>
                <div>
                    <div className="flex items-center">
                        <PhoneIcon className="w-6 sm:w-8 xl:w-12 h-6 sm:h-8 xl:h-12 mr-2 fill-secondary"/>
                        <a href="tel:4037633568" className="text-lg sm:text-2xl font-black hover:text-primary">(403) 763-3568</a>
                    </div>
                    <div className="flex items-center mt-1">
                        <EmailIcon className="w-6 sm:w-8 xl:w-12 h-6 sm:h-8 xl:h-12 mr-2 fill-secondary"/>
                        <a href="mailto:info@ramenviro.ca" className="text-lg sm:text-2xl font-black hover:text-primary">info@ramenviro.ca</a>
                    </div>
                </div>
            </div>
        </nav>
    )
}
