import React from 'react'
import Footer from './footer';

export default function Page({children}) {
    return (
        <main className="font-sans text-black">
            {children}
            <Footer/>
        </main>
    )
}
